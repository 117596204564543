import * as React from "react"
import { Link } from "gatsby"

// styles
const pageStyles = {
  backgroundColor:"#000",
  color: "white",
  width: "100vw",
  height: "100vh",
  padding: "96px",
  boxSizing: 'border-box',
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  textAlign: "center"
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <h1 style={headingStyles}>Page not found</h1>
      <p style={paragraphStyles}>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
        <Link style={{color:'white',marginTop:'1em',display:'block'}} to="/">Go home</Link>
      </p>
    </main>
  )
}

export default NotFoundPage
